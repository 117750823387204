<template>
    <div class="d-flex flex-column align-center">
        <product-banner>Add More Campaigns</product-banner>
        <product-card id="289">
            <template #title>1 Premium Big 6 Distribution</template>
            <template #description>
                Premium Big 6 Distribution gets your news article published on
                AT LEAST 6 additional world-renowned authority news brands with
                over 8 figure per month traffic like Business Insider, The Globe
                & Mail, Bar Chart, The Associated Press, Benzinga, Street
                Insider, and others.
            </template>
        </product-card>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { ProductBanner, ProductCard } from '@/components/ProductCard';
@Component({
    components: {
        ProductBanner,
        ProductCard
    }
})
export default class Premium extends Vue {
    mounted() {
        this.$emit(
            'appointment',
            'https://try.ampifire.com/meetings/jamieh2/big6'
        );
    }
}
</script>
